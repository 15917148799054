import { Component, OnInit ,Inject} from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-accessories-edit',
  templateUrl: './accessories-edit.component.html',
  styleUrls: ['./accessories-edit.component.scss'],
})
export class AccessoriesEditComponent implements OnInit {
  addForm:any
  id:any;
  userProfile:any=localStorage.getItem('userProfile');

  constructor(public dialogRef: MatDialogRef<AccessoriesEditComponent>,
    private toast:ToastComponent,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    
    private CrudService:CrudService) {
      this.userProfile = JSON.parse(this.userProfile);
      var barcode = Math.floor(100000000 + Math.random() * 900000000);

      this.addForm = {
        productName: "",
        brand: "",
        type: "",
        uses: "",
        currentQuantity: "",
        reorderLevel: "",
        reQuantity: "",
        material: "",
        color: "",
        purchasePrice: "",
        retailPrice: "",
        maxRetailDiscount: "",
        barCode: "",
        location: "",
        clientId: this.userProfile?.clientId?._id,
        branchId: this.CrudService?.branchId,
        HSN:"",
        Inventorytype:"Accessories",

        SGST:"",
      CGST:"",
      }
    this.id = this.data.id
    this.CrudService.getbyid(this.id,environment.api.accessories).then((res:any)=>{
      this.addForm = res;
      if(this.addForm.barCode == ''){
        this.addForm.barCode = barcode
      }
    })

   }

  ngOnInit() {}
  closedialog(){
    this.dialogRef.close();
  }
  save(){
    console.log('dataaaaaa')
    this.CrudService.edit(this.addForm,this.id,environment.api.accessories).then((res:any)=>{
      this.toast.presentToast("Edit Successfull")
    this.dialogRef.close()
    
    })
  }

}
