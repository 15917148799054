<div class="bg-white-fa">

  <div class="box-flex">
    <div>
      <input class="basic-input-field input-bg-value" matInput (keyup)="searchComplete()" [(ngModel)]="searchVariable"
        #input placeholder="Search...">
    </div>
    <div class="add-inventory">
      <button class="button-add-inv" (click)="openDialogadd('0ms','0ms')">Add Food</button>
    </div>
  </div>


  <div class="mat-elevation-z8">
    <div class="table-container">
      <table mat-table [dataSource]="dataFood" multiTemplateDataRows class="mat-elevation-z8">
        
        <ng-container matColumnDef="productName" sticky>
          <th mat-header-cell *matHeaderCellDef style="left: 0px;
          z-index: 9;"> Product Name </th>
          <td mat-cell *matCellDef="let row">

            <button style="background: transparent;
                    " mat-icon-button aria-label="expand row" *ngIf="row.childDuplicate.length>0"
              (click)="(expandedElement === row ? null : row);">
              <!-- <mat-icon *ngIf="expandedElement !== row.childDuplicate.length>0"><span>rohit</span></mat-icon> -->

              <img style="    width: 12px;
              transform: rotate(90deg);
              position: relative;
              top: 3px;
              margin-right: 7px;" *ngIf="expandedElement === row" src="../../../../assets/left-arrow-svgrepo-com.svg"
                alt="arrow-icon">
                <img style="    width: 12px;
                transform: rotate(270deg);
                position: relative;
                top: 3px;
                margin-right: 7px;" *ngIf="expandedElement !== row"
            src="../../../../assets/left-arrow-svgrepo-com.svg" alt="arrow-icon">

              <!-- <mat-icon *ngIf="expandedElement === row.childDuplicate.length > 0"><span>kapil</span></mat-icon> -->
            </button>
            {{row.productName}}
           
          </td>
        </ng-container>
        <!-- Progress Column -->
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef> Brand </th>
          <td mat-cell *matCellDef="let row"> {{row?.brand}}

          </td>
        </ng-container>

        <!-- Name Column -->


        <!-- Dosage Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Weight/Size </th>
          <td mat-cell *matCellDef="let row"> {{row?.weight}}

          </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="lifeStage">
          <th mat-header-cell *matHeaderCellDef> Life Stage </th>
          <td mat-cell *matCellDef="let row"> {{row.lifeStage}}

          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="nutritionalInformation">
          <th mat-header-cell *matHeaderCellDef> Nutritional information </th>
          <td mat-cell *matCellDef="let row"> {{row.nutritionalInformation}}

          </td>
        </ng-container>

        <!-- Progress Column -->
        <!-- <ng-container matColumnDef="ingredients">
          <th mat-header-cell *matHeaderCellDef > Ingredients
          </th>
          <td mat-cell *matCellDef="let row"> {{row.ingredients}} </td>
        </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type
          </th>
          <td mat-cell *matCellDef="let row"> {{row.type}}

          </td>
        </ng-container>

        <!-- Fruit Column -->
        <!-- <ng-container matColumnDef="protein">
          <th mat-header-cell *matHeaderCellDef > Protein source
          </th>
          <td mat-cell *matCellDef="let row"> {{row.proteinSource}} </td>
        </ng-container> -->
        <ng-container matColumnDef="flavour">
          <th mat-header-cell *matHeaderCellDef> Flavour
          </th>
          <td mat-cell *matCellDef="let row"> {{row.flavour}}

          </td>
        </ng-container>
        <ng-container matColumnDef="currentQuantity">
          <th mat-header-cell *matHeaderCellDef> Current Quantity
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="row?.currentQuantity < row?.reorderLevel ? 'stockalert' : ''"> {{row.currentQuantity}}

          </td>
        </ng-container>
        <ng-container matColumnDef="reorderLevel">
          <th mat-header-cell *matHeaderCellDef> Reorder Level

          </th>
          <td mat-cell *matCellDef="let row"> {{row.reorderLevel}}

          </td>
        </ng-container>

        <ng-container matColumnDef="reQuantity">
          <th mat-header-cell *matHeaderCellDef> Re- Qua..
          </th>
          <td mat-cell *matCellDef="let row"> {{row.reQuantity}}

          </td>
        </ng-container>

        <ng-container matColumnDef="batchNo">
          <th mat-header-cell *matHeaderCellDef> Lot/Batch No.
          </th>
          <td mat-cell *matCellDef="let row"> {{row.batchNo}}

          </td>
        </ng-container>
        <ng-container matColumnDef="purchasePrice">
          <th mat-header-cell *matHeaderCellDef> Purchase P...

          </th>
          <td mat-cell *matCellDef="let row"> {{row.purchasePrice}}

          </td>
        </ng-container>
        <ng-container matColumnDef="retailPrice">
          <th mat-header-cell *matHeaderCellDef> Retail Price
          </th>
          <td mat-cell *matCellDef="let row"> {{row.retailPrice}}

          </td>
        </ng-container>
        <ng-container matColumnDef="maxRetailDiscount">
          <th mat-header-cell *matHeaderCellDef> Max Retail Dis...
          </th>
          <td mat-cell *matCellDef="let row"> {{row.maxRetailDiscount}}

          </td>
        </ng-container>
        <ng-container matColumnDef="barCode">
          <th mat-header-cell *matHeaderCellDef> Barcode/SKU
          </th>
          <td mat-cell *matCellDef="let row"> {{row.barCode}}

          </td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef> Location
          </th>
          <td mat-cell *matCellDef="let row"> {{row.location}}

          </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row" class="actionbtn two-icons"><ion-button
              (click)="openDialog('0ms', '0ms',row._id)"><img
                src="../../../assets/ruler&pen.svg"></ion-button><ion-button (click)="deletebyid(row._id)"> <img
                src="../../../assets/Trash.svg"></ion-button>
            <ion-button (click)="AddNew(row._id)">
              <img src="../../../assets/plusIcon.svg">
            </ion-button>
          </td>
        </ng-container>


        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="display: none;"> </th>
          <td mat-cell *matCellDef="let element" style="display: none;">
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element.childDuplicate.length>0 ? null : element.childDuplicate.length>0); $event.stopPropagation()">
              <mat-icon *ngIf="expandedElement !== element.childDuplicate.length>0"><span>rohit</span></mat-icon>

              <mat-icon *ngIf="expandedElement === element.childDuplicate.length > 0"><span>kapil</span></mat-icon>
            </button>
          </td>
        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <table>
                <tbody>

                  <tr *ngFor="let data of expandedElement?.childDuplicate;let i =index;">


                    <td sticky>{{data?.productName}}</td>
                    <td>{{data?.brand}}</td>
                    <td>{{data?.weight}}</td>
                    <td>{{data?.lifeStage}}</td>
                    <td>{{data?.nutritionalInformation}}</td>
                    <td>{{data?.type}}</td>
                    <td>{{data?.flavour}}</td>
                    <td>{{data?.currentQuantity}}</td>

                    <td>{{data?.reorderLevel}}</td>
                    <td>{{data?.reQuantity}}</td>
                    <td>{{data?.batchNo}}</td>
                    <td>{{data?.purchasePrice}}</td>
                    <td>{{data?.retailPrice}}</td>
                    <td>{{data?.maxRetailDiscount}}</td>

                    <td>{{data?.barCode}}</td>
                    <td>{{data?.location}}</td>
                    <td stickyEnd *ngIf="userRole == 'Manager'">
                      <ion-button (click)="openDialog('0ms', '0ms',data._id)"><img
                          src="../../../assets/ruler&pen.svg"></ion-button><ion-button (click)="deletebyid(data._id)">
                        <img src="../../../assets/Trash.svg"></ion-button>
                      <!-- <ion-button (click)="AddNew(data._id)">
                        <img src="../../../assets/plusIcon.svg">
                      </ion-button> -->

                    </td>

                  </tr>
                </tbody>
              </table>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons #paginator>
    </mat-paginator>
  </div>
</div>