import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from 'src/app/core/constant';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { LoadingserviceService } from '../loading/loadingservice.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Router, CanActivate } from '@angular/router';

// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
@Injectable({
  providedIn: 'root'
})
export class AuthloginService implements CanActivate {
  apiUrl: string = AppSettings.API_ENDPOINT;
  headers: any;
  accessToken: any;
  uploading: boolean = false;
  constructor(
    private router: Router,
    public http: HttpClient,
    private loadingService: LoadingserviceService,
    private toast: ToastComponent,
    // private fcm:FCM,
    private platform: Platform

  ) {
    this.headers = new Headers();
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept', 'application/json');
    this.headers.append('content-type', 'application/json');
    ;
  }
  canActivate(): any {

    if (localStorage.getItem("token")) {
      this.router.navigate([localStorage.getItem('clinic') + '/main/dashboard']);
      return false;

    }
    else {
      return true;
    }
  }


  getClinic(slug: string) {
    this.loadingService.showLoading();
    let addRatingURL = this.apiUrl + 'clients/detail/' + slug;
    return new Promise((resolve, reject) => {
      this.http.get(addRatingURL, { headers: this.headers })
        .subscribe(res => {
          this.loadingService.hideLoading();
          resolve(res);
        }, (err) => {
          this.loadingService.hideLoading();
          reject(err);
        });
    });
  }




  add(info: any, model: any) {
    console.log(model, 'model')
    // this.getLoginUserData();
    this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, info, { headers: this.headers })
        .subscribe(res => {
          this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {
          this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });
  }


  checkNewLogin(userInfo: any, model: any) {


    this.loadingService.showLoading();
    let signupUrl = this.apiUrl + model + '/' + "checkLoginwithslug"
      + '?access_token=' + this.accessToken;

    return new Promise((resolve, reject) => {

      this.http.post(signupUrl, userInfo)
        .subscribe((res: any) => {
          this.loadingService.hideLoading();

          if (res && res.message == "Login successfully") {
            // if(this.platform.is('cordova')){
            //   console.log(this.fcm)

            //   let fcmtoken = "";
            //   this.fcm.getToken().then((token:any)=>{
            //     console.log(token);
            //    fcmtoken = token;
            //    let fcmData = {fcmToken : fcmtoken , _id : res.user._id}
            //    this.edit(fcmData , environment.api.businessUser).then((ress : any)=>{
            //    localStorage.setItem("userProfile" , JSON.stringify(ress.data));
            //    resolve(res);
            //    })
            //   })
            // }else{
            //   resolve(res);

            // }
            resolve(res);
          } else {
            resolve(res);

          }
        }, (err: any) => {
          this.loadingService.hideLoading();
          this.toast.presentToastError(err.error.message);
          reject(err);
        });
    });
  }
  customercheckNewLogin(userInfo: any, model: any) {


    this.loadingService.showLoading();
    let signupUrl = this.apiUrl + model + '/' + "checklogin"
      + '?access_token=' + this.accessToken;

    return new Promise((resolve, reject) => {

      this.http.post(signupUrl, userInfo)
        .subscribe((res: any) => {
          this.loadingService.hideLoading();

          if (res && res.message == "Login successfully") {
            // if(this.platform.is('cordova')){
            //   console.log(this.fcm)

            //   let fcmtoken = "";
            //   this.fcm.getToken().then((token:any)=>{
            //     console.log(token);
            //    fcmtoken = token;
            //    let fcmData = {fcmToken : fcmtoken , _id : res.user._id}
            //    this.edit(fcmData , environment.api.businessUser).then((ress : any)=>{
            //    localStorage.setItem("userProfile" , JSON.stringify(ress.data));
            //    resolve(res);
            //    })
            //   })
            // }else{
            //   resolve(res);

            // }
            resolve(res);
          } else {
            resolve(res);

          }
        }, (err: any) => {
          this.loadingService.hideLoading();
          this.toast.presentToastError(err.error.message);
          reject(err);
        });
    });
  }

  // addTreatmentWithoutLoading(info , model ) {
  //   this.getLoginUserData();
  //   this.uploading = true;
  //   let addRatingURL = this.apiUrl + model + '/' 
  //   return new Promise((resolve, reject) => {
  //   this.http.post(addRatingURL, this.getFormData(info),{headers:this.headers})
  //     .subscribe(res => {
  //   this.uploading = false;

  //     resolve(res);
  //     }, (err) => {
  //     this.toast.presentToastError(AppSettings.GENERIC_ERROR);
  //   this.uploading = false;

  //     reject(err);
  //     });
  //   });
  // }

  edit(info: any, model: any) {
    // this.getLoginUserData();
    this.loadingService.showLoading();



    let editRatingURL = this.apiUrl + model + '/';

    return new Promise((resolve, reject) => {

      this.http.patch(editRatingURL, info, { headers: this.headers })
        .subscribe(res => {
          this.loadingService.hideLoading();
          resolve(res);
        }, (err) => {
          this.loadingService.hideLoading();
          this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });
  }
  logout() {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'JWT_Token': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('token') ? 'Bearer ' + (localStorage.getItem('token') || '') : '',
    }
    );
    this.loadingService.showLoading();
    let logouturl = this.apiUrl + 'logout';
    return new Promise((resolve, reject) => {
      this.http.get(logouturl, { headers: headers })
        .subscribe(res => {
          this.loadingService.hideLoading();
          localStorage.removeItem("token");
          localStorage.removeItem('userProfile');
          resolve(res);
        }, (err) => {
          this.loadingService.hideLoading();
          this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });
  }


}
