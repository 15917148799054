import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserAddComponent } from 'src/app/components/user-add/user-add.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { ChoosePetComponent } from '../choose-pet/choose-pet.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NgStyle } from '@angular/common';

interface PetSpecies {
  value: string;
  viewValue: string;
  lifespan: number;

  pettype: any;
  petGender: any;
  petblood: any;

}
export interface CustomerInfoID {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-customer-lookup',
  templateUrl: './customer-lookup.page.html',
  styleUrls: ['./customer-lookup.page.scss'],
})
export class CustomerLookupPage implements AfterViewInit {
  detail: boolean = true;
  leng: any;
  petdetail: boolean = false;
  value: any = true;
  open: boolean = false;
  editpage: boolean = false;
  addForm: any;
  customerdata: any;
  searchVariable: any;
  editcustomerdata: any;
  editForm: any;
  editid: any;
  editpet: boolean = false;
  dog: any;
  maxperpage: number = 100;
  openpet: any;
  checked: boolean = true;
  checkedpet: boolean = false;
  dogId: any;
  userProfile: any = localStorage.getItem('userProfile');
  skeleton: boolean = false;
  ngOnInit() {


  }


  advance(event: any) {
    console.log(event, 'event')
    this.detail = false;
    this.value = false;
    if (event == false) {
      this.detail = true;
      this.value = true;
    }
  }
  advancepet(event: any) {
    console.log(event, 'event')
    this.petdetail = true;
    this.value = false;
    if (event == false) {
      this.petdetail = false;
      this.value = true;
    }
  }

  displayedColumns: string[] = ['id', 'petname', 'name', 'mobile', 'email', 'registrationType', 'addresss', 'notes', 'createdAt',
    'lastvisit', 'followup', 'action'];
  dataSource: any = new MatTableDataSource([]);
  dataSourceWithPageSize: any = new MatTableDataSource([]);
  //pageSize = 10;//
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];//
  totalCount: any = 0;//
  skip = 0;
  parentId: any;
  limit: any = 100;
  len: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  //length = 100;
  clientDetails: any = localStorage.getItem('clientDetail');
  dogLength: any = [];
  id: any
  types: any
  temp: any[];
  addap: any
  parentname: any
  branchId: any;
  flag: boolean = false;
  treatedByYou: boolean = true;
  petForm: any
  // public dialogRef: MatDialogRef<FoodEditComponent>,

  constructor(

    public dialog: MatDialog,
    private route: Router,
    private toast: ToastComponent,
    private crudService: CrudService,
    private activatedRoute: ActivatedRoute,
    private _liveAnnouncer: LiveAnnouncer
  ) {
    this.clientDetails = JSON.parse(this.clientDetails);
    this.userProfile = JSON.parse(this.userProfile);
    setTimeout(() => {
      this.skeleton = true;
    }, 2000);
    this.petForm = {
      "name": "",
      "species": "",
      "weight": "",
      "breed": "",
      "gender": "",
      "color": "",
      "mood": "",
      "age": "",
      "petmotherName": "",
      "licNo": "",
      "rabisTag": "",
      "microchipNo": "",
      "preferedDoctor": "",
      "registerDate": "",
      "lastvisit": "",
      "followUp": "",
      "vaccinationDue": "",
      "blood": "",
      "month": "",
      "images": "",
      "clientId": this.userProfile?.clientId?._id,
      "branchId": '',

    }

    this.addForm = {
      parentDetails: {
        "name": "",
        "mobile": "",
        "email": "",
        "address": "",
        "notes": "",
        "guardian": "",
        "guardianEmail": "",
        "guardianId": "",
        "aadharNumber": "",
        // "registrationType": "",
        "role": "customer",
        "approval": "",
        "profileImage": "",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": '',
      },
      petDetails: {
        "name": "",
        "species": "",
        "weight": "",
        "breed": "",
        "gender": "",
        "color": "",
        "mood": "",
        "age": "",
        "petmotherName": "",
        "licNo": "",
        "rabisTag": "",
        "microchipNo": "",
        "preferedDoctor": "",
        "registerDate": "",
        "lastvisit": "",
        "followUp": "",
        "vaccinationDue": "",
        "blood": "",
        "month": "",
        "images": "",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": '',

      },

    }

    this.editForm = {
      parentDetails: {
        "name": "",
        "mobile": "",
        "email": "",
        "address": "",
        "notes": "",
        "guardian": "",
        "guardianEmail": "",
        "guardianId": "",
        "aadharNumber": "",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": "",

      },
      petDetails: {
        "name": "",
        "species": "",
        "weight": "",
        "breed": "",
        "gender": "",
        "color": "",
        "mood": "",
        "age": "",
        "petmotherName": "",
        "licNo": "",
        "rabisTag": "",
        "microchipNo": "",
        "preferedDoctor": "",
        "registerDate": "",
        "lastvisit": "",
        "followUp": "",
        "vaccinationDue": "",
        "blood": "",
        "month": "",
        "images": "",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": "",
        "userId": ""


      },
    }
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getall();
    this.addForm.parentDetails.guardianId = 'Aadhar';

    this.getCustomerCount()


  }



  ngAfterViewInit() {


  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceWithPageSize.paginator = this.paginatorPageSize;

    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {

    }
  }


  /** Announce the change in sort state for assistive technology. */
  // announceSortChange(sortState: Sort) {
  //   // This example uses English messages. If your application supports
  //   // multiple language, you would internationalize these strings.
  //   // Furthermore, you can customize the message to add additional
  //   // details about the values being sorted.
  //   console.log(sortState,'sortState')
  //   if (sortState.direction) {
  //     this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  //   } else {
  //     this._liveAnnouncer.announce('Sorting cleared');
  //   }
  // }

  viewprofile(id: any, customer: any) {
      this.crudService.getbyid(id, 'appointment/DogLogsPendingPayment').then((res: any) => {
        console.log(res,'resssssss')
        if(res != ''){
          this.route.navigate([localStorage.getItem('clinic') + "/manager-main/consultation-pet-info/" + res[0]?._id + '/' + 'appointment' + '/nosession'])

        } else {
          this.route.navigate([localStorage.getItem('clinic') + "/manager-main/consultation-pet-info/" + id + '/' + customer + '/nosession'])

        }



      })
    
  }

  searchComplete() {
    console.log(this.searchVariable)
    if (this.searchVariable != "") {
      let filter = {
        "where": {
          $or: [{
            "name": { "$regex": this.searchVariable, "$options": "i" }
          },
          { "email": { "$regex": this.searchVariable, "$options": "i" } },
          { "mobile": { "$regex": this.searchVariable, "$options": "i" } },
          { "dog.name": { "$regex": this.searchVariable, "$options": "i" } },
          ],
          $and: [
            { "branchId": this.crudService?.branchId },
            {
              "clientId": this.userProfile?.clientId?._id,
            }
          ],
        },
        "skip": this.skip,
        "limit": this.limit
      }
      this.crudService.get(environment.api.customer, filter).then((res: any) => {
        this.dataSource.data = res;
        console.log('this.datasourace', this.dataSource.data)
        setTimeout(() => {
          if (!res.length) {
            this.toast.presentToastError("Data is not found related to search : " + this.searchVariable)
          }
        }, 100);
      })
    }
    if (this.searchVariable == "") {

      this.getall();
    }

  }
  openCustomeraddd(event: any) {
    if (event === 'true') {
      this.open = true;
    }
    else {
      this.open = false;
    }

  }
  openpets(event: any) {
    if (event === 'true') {
      this.openpet = true;
    }
    else {
      this.openpet = false;
    }
  }

  openCustomeredit(event: any) {

    if (event === 'true') {

      this.editpage = true;
    }
    else {
      this.editpage = false;
    }
    this.editpet = false;
  }

  openDialog(id: any, name: any): void {
    const dialogref = this.dialog.open(ChoosePetComponent, {
      data: {
        id: id,
        name: name,
        pet: "PetSchedule"

      }
    });
    dialogref.afterClosed().subscribe((res: any) => {
      if (res?.data1) {
        this.openpet = true;
        this.parentname = res?.data
        this.parentId = res?.data1
        console.log(res, 'datatattata')

      }
    })

  }
  openDialogpet(id: any, name: any): void {
    console.log('name', name)
    const dialogref = this.dialog.open(ChoosePetComponent, {
      data: {
        id: id,
        name: name,
        pet: "PetCheck"
      }
    });
    dialogref.afterClosed().subscribe((res: any) => {

      if (res?.data1) {
        this.openpet = true;
        this.parentname = res?.data
        this.parentId = res?.data1
        console.log(res, 'datatattata')

      }
    })

  }
  checkin(id: any, name: any, owner: any) {
    console.log(id, 'id', name, owner);

    let data = {
      "check": "True",
      "dogs": id,
      "ownerId": owner,
      "status": "Pending",
      "appoinmentTime": new Date(),
      "branchId": this.crudService?.branchId,
      "clientId": this.userProfile?.clientId?._id,
      "paymentStatus": "On Going"


    }
    this.crudService.add(data, 'appointment/checkIn').then((res: any) => {
      this.toast.presentToast("Pet " + name + " is added to consultation");
      this.route.navigate([localStorage.getItem('clinic') + "/manager-main/consultation"])


    })
  }
  addappoint(id: any, ownerid: any, name: any) {
    console.log(id, 'id');
    if (id != undefined) {
      console.log(id, 'idid')
      this.addap = {
        "ownerId": ownerid,
        "dogs": id,
        "appoinmentTime": new Date(),
        "status": "Pending",
        "check": "False",
        "branchId": this.crudService?.branchId,
        "clientId": this.userProfile?.clientId?._id,
        "paymentStatus": "On Going"

        // "vet":this.name.preferedDoctor,
      }
      this.crudService.addappoint(this.addap, 'appointment/addNewApppointment').then((res: any) => {
        if (res.message == "Pet Already Exist") {
          this.toast.presentToast("An appointment has been booked already");
        } else {
          console.log(localStorage.getItem('clinic'), 'local')
          this.toast.presentToast("Pet " + name + " is added to consultation");
          this.route.navigate([localStorage.getItem('clinic') + '/manager-main/consultation']);
        }

      })

    }
  }


  save(form: any) {
    //this.showId(this.branchId)
    this.addForm.parentDetails.branchId = this.crudService?.branchId;
    this.addForm.petDetails.branchId = this.crudService?.branchId;
    this.addForm.petDetails.userId = this.parentId
    console.log(this.addForm, 'addform', this.addForm.petDetails.branchId
    )


    this.crudService.add(this.addForm, environment.api.customer).then((res: any) => {
      if (res?.message == "Mobile Number Already Exist") {
        this.toast.presentToast(res?.message);

      } else {
        this.getall();
        this.open = false;
        form.reset();
        this.toast.presentToast("Customer Added Successfully");
        this.addForm.petDetails = [];
        this.getCustomerCount();

        this.route.navigate([localStorage.getItem('clinic') + "/manager-main/customer-lookup"]);
      }

    })

  }
  getall() {
    this.treatedByYou = true;


    let filter = {

      where: {
        $and: [
          { "branchId": this.crudService?.branchId },
          {
            "clientId": this.userProfile?.clientId?._id,
          }
        ],
      },

      "skip": this.skip,
      "limit": this.limit
    }
    this.crudService.get(environment.api.customer, filter).then((res: any) => {
      this.flag = this.crudService.flag
      this.crudService?.flag
      this.dataSource.data = res.reverse();
      this.dataSourceWithPageSize.data = this.dataSource.data;
      this.leng = res.length;
      // this.route.navigate([localStorage.getItem('clinic') + '/manager-main/customer-lookup'])
      console.log(this.dataSource.data, 'customer data.....');
      setTimeout(() => {
        this.flag = false;
      },);
    })
  }


  getCustomerCount() {
    let filter = {
      "branchId": this.crudService?.branchId,
      "clientId": this.userProfile?.clientId?._id

    }
    this.crudService.count(environment.api.customer, filter).then((res: any) => {
      console.log(res);
      this.totalCount = res;
    })
  }


  // getServerData(event: any) {
  //   let filter = {
  //     where: {
  //       $and: [
  //         { "branchId": this.crudService?.branchId },
  //         { "clientId": this.userProfile?.clientId?._id }
  //       ]
  //     },
  //     "limit": event.pageSize,
  //     "skip": event.pageIndex * 10,
  //   }
  //   console.log("event9090", filter);

  //   this.crudService.get(environment.api.customer, filter).then((res: any) => {
  //     this.dataSource.data = res;
  //     console.log(res)
  //     this.skip += res.length;
  //     console.log(res)
  //   })

  // }
  onChange(data: any) {
    console.log(data, 'data');
    this.types = data;

  }
  deletebyid(id: any) {
    this.crudService.deleteid(id, environment.api.customer).then((res: any) => {
      this.toast.presentToast("Deletion Complete")
      this.getall();

    })

  }
  deletepetDetails(index: any) {
    this.addForm.petDetails.splice(index, 1);
  }
  edit(id: any) {

    this.editid = id;
    console.log(this.editForm, 'idddddd')

    this.crudService.getbyid(id, environment.api.customer).then((res: any) => {
      this.editForm.parentDetails = res;
      this.dog = res?.dog
      res?.dog.map((item: any) => {
        if (item._id == this.dogId) {
          this.editForm.petDetails = item;
          this.openCustomeredit('true');
        }
      });

      if(this.editForm.parentDetails.guardianId == '' || this.editForm.parentDetails.guardianId == undefined){
        this.editForm.parentDetails.guardianId = 'Aadhar'
      } 
      console.log(this.editForm.parentDetails.guardianId, 'items')
    })
  }
  multipleEdit(id: any) {
    const dialogref = this.dialog.open(ChoosePetComponent, {
      data: {
        id: id,
        pet: "editpet"
      }
    });
    dialogref.afterClosed().subscribe((res: any) => {
      if (res?.data) {


        this.dogId = res?.data;
        this.edit(res?.data2);
        console.log(this.dogId, 'datatata')
      }
    })
  }

  openedit() {
    this.editpet = true;
    console.log('changeeeeeeeee')
  }

  editsave(form: any) {
    console.log(form, 'dataaaaaa')
    console.log("customer", environment.api.customer)
    console.log(this.editForm, 'dataa')
    this.crudService.editpet(this.editForm, this.editid, this.editForm.petDetails._id, environment.api.customer).then((res: any) => {
      this.getall();
      this.editpage = false;
      form.reset();
      this.toast.presentToast("Updated Successfull");
      this.editForm.petDetails = [];
      this.editpet = false;

      this.route.navigate([localStorage.getItem('clinic') + "/manager-main/customer-lookup"]);
    })

  }
  petProfile(id: any) {
    this.route.navigate([localStorage.getItem('clinic') + '/manager-main/consultation-pet-info/' + id])

  }
  openpetForm(event: any, id: any, name: any) {
    this.parentname = name;
    this.parentId = id;
    console.log(this.parentId, 'parentId')
    if (event === 'true') {
      this.openpet = true;
    }
    else {
      this.openpet = false;
    }
  }
  savePet(form1: any) {
    this.petForm.userId = this.parentId;
    console.log(this.petForm, 'addform', this.petForm.userId)
    this.crudService.add(this.petForm, environment.api.dogspet).then((res: any) => {
      this.getall();
      this.parentId = "";
      this.openpet = false;
      form1.reset();
      this.toast.presentToast("Pet Added Successfully");
      this.getCustomerCount();
      this.route.navigate([localStorage.getItem('clinic') + "/manager-main/customer-lookup"]);
    })


  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  // if (this.dataSource.paginator) {
  //   this.dataSource.paginator.firstPage();
  // }

  customerinfoID: CustomerInfoID[] = [
    { value: 'aadhar', viewValue: 'Aadhar' },
    { value: 'pan', viewValue: 'Pan Card' },
    { value: 'driving', viewValue: 'Driving Licence' },
  ];
  petSpecies: PetSpecies[] = [
    { value: 'steak-0', viewValue: 'Dog', lifespan: 20, pettype: [{ value: 'Golden Retriever' }, { value: 'German Shepherd' }, { value: 'Labrador Retriever' }, { value: 'Bulldog' }, { value: 'Poodle' }, { value: 'Beagle' }, { value: 'Rottweiler' }, { value: 'Yorkshire Terrier' }, { value: 'Boxer' }, { value: 'Dachshund' }], petGender: [{ value: 'Male Intact' }, { value: 'Male Neutered' }, { value: 'Female Intact' }, { value: 'Female Spayed' }, { value: 'Non-Binary' }, { value: 'GenderFluid' }, { value: 'Two-Spirit' }, { value: 'Dont Know' }], petblood: [{ value: 'DEA 1.1 Positive' }, { value: 'DEA 1.1 Negative' }, { value: 'DEA 7 Positive' }] },
    { value: 'pizza-1', viewValue: 'Cat', lifespan: 20, pettype: [{ value: 'Persian' }, { value: 'Siamese' }, { value: 'Maine Coon' }, { value: 'Ragdoll' }, { value: 'British Shorthair' }, { value: 'Sphynx' }, { value: 'Devon Rex' }, { value: 'Russian Blue' }, { value: 'Bengal' }, { value: 'Abyssinian' }], petGender: [{ value: 'Male Intact' }, { value: 'Male Neutered' }, { value: 'Female Intact' }, { value: 'Female Spayed' }, { value: 'Non-Binary' }, { value: 'GenderFluid' }, { value: 'Two-Spirit' }, { value: 'Dont Know' }], petblood: [{ value: 'A' }, { value: 'B' }, { value: 'AB' }, { value: 'Bombay' }] },
    { value: 'tacos-2', viewValue: 'Fish', lifespan: 10, pettype: [{ value: 'Goldfish' }, { value: 'Beta Fish' }, { value: 'Guppy' }, { value: 'Swordtail' }, { value: 'Neon Tetra' }, { value: 'Koi' }, { value: 'Cichlid' }, { value: 'Grouper' }, { value: 'Discus' }, { value: 'Arrowana' }], petGender: [{ value: 'Male' }, { value: 'Female' }, { value: 'Hermaphrodite' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-1', viewValue: 'Rabbit', lifespan: 12, pettype: [{ value: 'Lionhead' }, { value: 'Netherland Dwarf' }, { value: 'Lop' }, { value: 'Angora' }, { value: 'Rex' }, { value: 'Himalayan' }, { value: 'Jersey Wooly' }, { value: 'Hotot' }], petGender: [{ value: 'Male Intact' }, { value: 'Male Neutered' }, { value: 'Female Intact' }, { value: 'Female Spayed' }, { value: 'Non-Binary' }, { value: 'GenderFluid' }, { value: 'Two-Spirit' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-2', viewValue: 'Hamster', lifespan: 3, pettype: [{ value: 'Syrian' }, { value: 'Dwarf' }, { value: 'Chinese' }, { value: 'Roborovski' }, { value: 'Winter White' }, { value: 'Cambell' }], petGender: [{ value: 'Male Intact' }, { value: 'Male Neutered' }, { value: 'Female Intact' }, { value: 'Female Spayed' }, { value: 'Non-Binary' }, { value: 'GenderFluid' }, { value: 'Two-Spirit' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-3', viewValue: 'Guinea Pig', lifespan: 8, pettype: [{ value: 'American' }, { value: 'Abyssinian' }, { value: 'Peruvian' }, { value: 'Silkie' }, { value: 'Texel' }, { value: 'Teddy' }], petGender: [{ value: 'Male Intact' }, { value: 'Male Neutered' }, { value: 'Female Intact' }, { value: 'Female Spayed' }, { value: 'Non-Binary' }, { value: 'GenderFluid' }, { value: 'Two-Spirit' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-4', viewValue: 'Turtle', lifespan: 80, pettype: [{ value: 'Red-Eared Slider' }, { value: 'Box Turtle' }, { value: 'Peruvian' }, { value: 'Snapping Turtle' }, { value: 'Painted Turtle' }, { value: 'Map Turtle' }, { value: 'Wood Turtle' }, { value: 'Spotted Turtle' }], petGender: [{ value: 'Male' }, { value: 'Female' }, { value: 'Hermaphrodite' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-5', viewValue: 'Parrot', lifespan: 50, pettype: [{ value: 'African Grey Parrot' }, { value: 'Amazon Parrot' }, { value: 'Macaw' }, { value: 'Cockatoo' }, { value: 'Parakeet' }, { value: 'Eclectus Parrot' }, { value: 'Conure' }, { value: 'Lorikeet' }, { value: 'Lovebird' }, { value: 'Pionus' }], petGender: [{ value: 'Male' }, { value: 'Female' }, { value: 'Hermaphrodite' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-6', viewValue: 'Snake', lifespan: 30, pettype: [{ value: 'Boa Constrictor' }, { value: 'Cobra' }, { value: 'Pythons' }, { value: 'Cockatoo' }, { value: 'Rattlesnake' }, { value: 'Vipers' }, { value: 'Coral Snake' }, { value: 'Garter Snake' }, { value: 'Water Snake' }, { value: 'King Snake' }, { value: 'Rat Snake' }], petGender: [{ value: 'Male' }, { value: 'Female' }, { value: 'Hermaphrodite' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },
    { value: 'steak-7', viewValue: 'Lizard', lifespan: 20, pettype: [{ value: 'Bearded Dragon' }, { value: 'Leopard Gecko' }, { value: 'Iguana' }, { value: 'Chameleon' }, { value: 'Gecko' }, { value: 'Vipers' }, { value: 'Skink' }, { value: 'Anole' }, { value: 'Uromastyx' }, { value: 'Agama' }, { value: 'Monitors' }], petGender: [{ value: 'Male' }, { value: 'Female' }, { value: 'Hermaphrodite' }, { value: 'Dont Know' }], petblood: [{ value: 'NA' }] },

  ];
  doSomething() {
    console.log(this.addForm.petDetails.species, 'console');
    console.log(this.editForm.petDetails.species, 'console');

  }
  govtId() {
    console.log(this.addForm.parentDetails.guardianId, 'console');
    console.log(this.editForm.parentDetails.guardianId, 'console');
    this.addForm.parentDetails.aadharNumber = ''
    this.editForm.parentDetails.aadharNumber = ''


  }

}

// function createNewUser(id: number): UserData {
//   const name =
//     NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
//     ' ' +
//     NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
//     '.';

//   return {
//     id: id.toString(),
//     name: name,
//     strength: STRENGTH[Math.round(Math.random() * (STRENGTH.length - 1))],
//     brand: Math.round(Math.random() * 100).toString(),
//     dosageform: DOSAGEFORM[Math.round(Math.random() * (DOSAGEFORM.length - 1))],
//   };

// }
