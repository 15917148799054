import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerLookupPage } from 'src/app/pages/manager/customer-lookup/customer-lookup.page';
import { CrudService } from 'src/app/services/crud/crud.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';
import { AuthloginService } from 'src/app/services/login/authlogin.service';
import { FileUploadService } from 'src/app/services/fileupload/file-upload.service';
import { FileUpload } from 'src/app/shared/classes/file-upload';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
selectedCity:any = 'Jp Nagar';
allmenu:any
skeleton: boolean = true;
slug:any=localStorage.getItem('clinic')
currentUpload: FileUpload;
  mainbranch: any;
  branches:any=[];
  branchName:any;
  userRole:any;
  url:any;
  changedefault:any;
  setdefault:any;
  checkk:boolean=false;
  check:boolean=false;
  urlclinic:any;
  clientDetails:any=localStorage.getItem('clientDetail');
  userProfile:any=localStorage.getItem('userProfile');
  addForm:any;
  addForm1:any;
  selectedFiles:any
  constructor(public router: Router,
    public toast : ToastComponent,
    public CrudService:CrudService,public customerPage:CustomerLookupPage,
    private FileUploadService:FileUploadService,
    private auth:AuthloginService) {
      setTimeout(() => {
        this.skeleton=false;
      }, 2000);   
      this.clientDetails = JSON.parse(this.clientDetails)
      console.log(this.clientDetails,'client')
      this.userProfile = JSON.parse(this.userProfile);
      this.getbranch();

    console.log("****Login user role*****",this.userProfile);
    this.userRole = this.userProfile?.role?.name;
      console.log("ssssssluuuuuuuugggg", this.slug)
      this.addForm={
        profileImage:""
      }
      this.addForm1={
        image:""
      }
      this.CrudService.getbyid(this.userProfile?._id,environment.api.businessUser).then((res)=>{
        console.log(res,'detailssssssss')
        this.addForm = res;
        this.url=this.addForm?.profileImage?.url;
        if(this.url == undefined || this.urlclinic == ''){
          this.check =  true

        }
        console.log(this.url);
      })
      this.CrudService.getbyid(this.userProfile?.clientId._id,environment.api.client).then((res)=>{
        console.log(res,'detailssssssssclient')
        this.addForm1= res;
        this.urlclinic=this.addForm1?.image?.url;
        console.log(this.urlclinic,'urlclininc')
        if(this.urlclinic == undefined || this.urlclinic == ''){
          this.check =  true

        }
      })
    }

  ngOnInit() {

    
  }
  onFileInput (event:any) {
  this.selectedFiles = event.target.files;
  let file = this.selectedFiles.item(0);
  this.currentUpload = new FileUpload(file);
  this.uploadImage()
  console.log(file,'file')
  }


  uploadImage(){
    this.FileUploadService.pushUpload(this.currentUpload,'/AdminProfileImage/'+this.userProfile?._id).then((res)=>{
    console.log(res);
    this.addForm.profileImage=res;
    this.editSubAdmin();
    });
  }

  editSubAdmin(){
    console.log(this.addForm?.profileImage?.url,'getting into edit')
    this.CrudService.edit(this.addForm,this.userProfile?._id,environment.api.businessUser).then((res)=>{
    console.log(res,'ressssssssssssssss');
    this.CrudService.getbyid(this.userProfile?._id,environment.api.businessUser).then((res)=>{
      console.log(res,'detailssssssss')
      this.addForm = res;
      this.url=this.addForm?.profileImage?.url;
    
    })
    
  })

}
  onFileInputclinic (event:any) {
    this.selectedFiles = event.target.files;
    let file = this.selectedFiles.item(0);
    this.currentUpload = new FileUpload(file);
    this.uploadImageclinic()
    console.log(file,'file')
    }
  
  
    uploadImageclinic(){
      this.FileUploadService.pushUpload(this.currentUpload,'/AdminClinicImage/'+this.userProfile?.clientId._id).then((res)=>{
        console.log(res);
        this.addForm1.image=res;
        console.log(this.addForm1?.image);
        this.editSubAdminclinic();   
      });
    }
  
    editSubAdminclinic(){
      console.log(this.addForm1?.image,'getting into edit')
      this.CrudService.edit(this.addForm1,this.userProfile?.clientId._id,"clients/logo").then((res)=>{
        // console.log(res,'ressssssssssssssss')
        this.CrudService.getbyid(this.userProfile?.clientId._id,environment.api.client).then((resp)=>{
          console.log(resp,'detailssssssss')
          this.addForm1= resp;
          this.urlclinic=this.addForm1?.image?.url;
        })
      })
   
    }

   
  
  rolesuser() {
    this.router.navigate([localStorage.getItem('clinic')+'/manager-main/roles-users']);
  }
  dashboardpage() {
    console.log(localStorage.getItem('clinic'),"clinic name sidebar");
    this.router.navigate([localStorage.getItem('clinic')+'/main/dashboard']);
  }
  customerlookup(){
    this.router.navigate([localStorage.getItem('clinic')+'/manager-main/customer-lookup'])
  }
  inventorypage() {
    this.router.navigate([localStorage.getItem('clinic')+'/main/admin-table']);

  }
  transactionpage() {
    this.router.navigate([localStorage.getItem('clinic')+'/main/admin-sales-transactions']);
  }
  supplierpage() {
    this.router.navigate([localStorage.getItem('clinic')+'/main/supplier']);
  }
  servicepage() {
    this.router.navigate([localStorage.getItem('clinic')+'/main/service']);

  }
  branchpage() {
    this.router.navigate([localStorage.getItem('clinic')+'/main/branches']);
  }
  consultation() {
    console.log(localStorage.getItem('clinic'),"clinic name sidebar");
    this.router.navigate([localStorage.getItem('clinic')+'/manager-main/consultation']);
  }
  FollowUp(){
    console.log(localStorage.getItem('clinic'),"clinic name sidebar");
    this.router.navigate([localStorage.getItem('clinic')+'/manager-main/follow-up']);
  }
  Sales(){
    console.log(localStorage.getItem('clinic'),"clinic name sidebar");
    this.router.navigate([localStorage.getItem('clinic')+'/main/admin-sales-transactions']);
  }
  getall(){
    let filter = {

    }
    this.CrudService.get(environment.api.permission,filter).then((res:any)=>{
      this.allmenu = res;
    })
  }

  getbranch(){
    var data :any;
    console.log(this.branches,"responssse --------");
    console.log(this.CrudService?.branchId,this.CrudService?.flag,'this.CrudService.branchId')
    var filter = {
        "markBranch": true,
        "clientId": this.userProfile?.clientId?._id 
    }
    this.CrudService.get(environment.api.branch,filter).then((res:any)=>{
    this.CrudService.branchId=res[0]?._id
    })
    console.log(this.CrudService?.branchId,this.CrudService?.flag,' this.CrudService.branchId')
    if(this.userProfile?.role?.name == 'Doctor' || this.userProfile?.role?.name == 'Nursing' || this.userProfile?.role?.name == 'Front Desk'){
    this.userProfile?.branchId?.map((item:any)=>{
        console.log("*******************************",item);
        this.CrudService.getbyid(item,environment.api.branch).then((res:any)=>{                
        this.branches.push(res);  
        })
      })
      
    } 
    
    console.log(this.userProfile,'clinicdata')
    if(this.userProfile?.role?.name == "Manager"){

      this.CrudService.getbyid(this.userProfile?.clientId?._id,environment.api.branch+'/all').then((res:any)=>{
      this.branches = res;
      console.log("*******************************",res);
       
      })
    }
   
    // this.router.navigate([localStorage.getItem('clinic')+"/main/dashboard"])
  }
  branch(dataid:any)
  {         
     console.log(dataid,"dataid");
     this.changedefault={
     "markBranch" :false
     };
     this.CrudService.edit(this.changedefault,this.CrudService.branchId,environment.api.branch).then((res:any)=>{
     console.log(res,'changedefault');
    //  dataid=res._id;
     })
     this.setdefault={
     "markBranch" :true
     }
      this.CrudService.edit(this.setdefault,dataid,environment.api.branch).then((resp:any)=>{
      console.log(resp,'setdefault');
      dataid=resp._id;     
     })  
     localStorage.setItem('branchId',dataid);
     this.CrudService.branchId=dataid;
     this.CrudService.flag = true;
     //  this.customerPage.getall();
     this.toast.presentToast("Swith Branch");
     this.router.navigate([localStorage.getItem('clinic')+"/manager-main/customer-lookup"]);
     location.reload();
     console.log(dataid,'data');
}

  checkvalue(data:any)
  {
    console.log("inputdata",data);
  }

  signout(){
    this.auth.logout().then((res:any)=>{
    this.router.navigate([localStorage.getItem('clinic')+'/business'])
    })
  }
}
