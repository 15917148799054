import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {

  displayedColumns: string[] = ['name', 'action'];
  displayedColumnssearch: string[] = ['productName', 'action'];

  displayedColumnsmfa: string[] = ['name', 'qty', 'action'];

  displayedColumns1: string[] = ['medication', 'price', 'discount', 'gst', 'afterdiscount'];
  displayedColumns1mfa: string[] = ['medication', 'price', 'qty', 'discount', 'gst', 'afterdiscount'];

  dataSource: any = new MatTableDataSource([]);
  searchVariable: any;
  userProfile: any = localStorage.getItem('userProfile');
  addForm: any;
  service: any
  serviceData: any;
  medicine: any = [];
  food: any = [];
  addDiscount: any = []
  accessories: any = [];
  discount: any = 0
  addForm1: any
  addForm0: any
  masterSearch:any
  othersvalue: boolean = false;
  collectionname: any
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<BillingComponent>,
    private toast: ToastComponent,
    private CrudService: CrudService,
    private route: Router

  ) {
    console.log(this.data, 'datata')
    this.userProfile = JSON.parse(this.userProfile);
    if (this.data?.editId && this.data?.appoinmentId) {
      this.getbilling()
    }
    if (this.data?.editId && this.data?.sessionId) {
      this.getbillingSession()

    }
   
    this.getServices();
    if (this.data?.title == 'Medicine') {
      this.getmedicine();
    }
    console.log(this.data, 'data')
    this.addForm1 = {
      "quantity": 1
    }
    this.addForm0 = {
      "quantity": 1
    }
    if (this.data?.appoinmentId) {
      this.addForm = {
        "date": new Date(),
        "serviceType": "",
        "serviceId": "",
        "serviceName": "",
        "price": "",
        "discount": "",
        "netPrice": "",
        "quantity": 1,
        "GST": "",
        "GSTAmount": "",
        "discountAmount": "",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
        "dogId": this.data?.dogId,
        "ownerId": this.data?.ownerId,
        "appointmentId": this.data?.appoinmentId,
        "originalPrice":""
      }
    } else {
      this.addForm = {
        "date": new Date(),
        "serviceType": "",
        "serviceId": "",
        "serviceName": "",
        "price": "",
        "discount": "",
        "netPrice": "",
        "quantity": 1,
        "GST": "",
        "GSTAmount": "",
        "discountAmount": "",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
        "dogId": this.data?.dogId,
        "ownerId": this.data?.ownerId,
        "sessionId": this.data?.sessionId,
        "originalPrice":""

      }
    }


  }

  ngOnInit() { }
  getbilling() {
    if (this.data?.title == 'Medicineedit') {
      let filter = {
        "appointmentId": this.data?.appoinmentId,
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
      }
      this.CrudService.getbyPet(filter, 'billing/getServicelist/byTypeMedicine').then((res: any) => {
        this.serviceData = res;
        this.discount = 0
        res?.map((item: any) => {
          this.discount += item?.netPrice
          // this.addDiscount.push(this.discount)
        })
        console.log(this.serviceData, 'services', this.discount)
      })

    } else {
      if (this.data?.title == 'Treatmentedit') {
        this.service = 'Treatment'
      }

      if (this.data?.title == 'Diagnosticedit') {
        this.service = 'Diagnostic'

      }
      if (this.data?.title == 'Nursingedit') {
        this.service = 'Nursing'

      }
      if (this.data?.title == 'Supportedit') {
        this.service = 'Support'
      }

      let filter = {
        "serviceType": this.service,
        "appointmentId": this.data?.appoinmentId,
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
      }


      this.CrudService.getbyPet(filter, 'billing/getServicelist/byType').then((res: any) => {
        this.serviceData = res;
        this.discount = 0
        res?.map((item: any) => {
          this.discount += item?.netPrice
          // this.addDiscount.push(this.discount)
        })
        console.log(this.serviceData, 'services', this.discount)
      })
    }

  }
  getbillingSession() {
    if (this.data?.title == 'Medicineedit') {
      let filter = {
        "sessionId": this.data?.sessionId,
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
      }
      this.CrudService.getbyPet(filter, 'billing/getServicelistSession/byTypeMedicine').then((res: any) => {
        this.serviceData = res;
        this.discount = 0
        res?.map((item: any) => {
          this.discount += item?.netPrice
          // this.addDiscount.push(this.discount)
        })
        console.log(this.serviceData, 'services', this.discount)
      })

    } else {
      if (this.data?.title == 'Treatmentedit') {
        this.service = 'Treatment'
      }

      if (this.data?.title == 'Diagnosticedit') {
        this.service = 'Diagnostic'

      }
      if (this.data?.title == 'Nursingedit') {
        this.service = 'Nursing'

      }
      if (this.data?.title == 'Supportedit') {
        this.service = 'Support'
      }

      let filter = {
        "serviceType": this.service,
        "sessionId": this.data?.sessionId,
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
      }


      this.CrudService.getbyPet(filter, 'billing/getServicelistSession/byType').then((res: any) => {
        this.serviceData = res;
        this.discount = 0
        res?.map((item: any) => {
          this.discount += item?.netPrice
          // this.addDiscount.push(this.discount)
        })
        console.log(this.serviceData, 'services', this.discount)
      })
    }

  }
  others() {
    this.othersvalue = true;
  }
  saveOthers() {
    if (this.addForm.price != '') {
      this.addForm.serviceName = 'Others';
      this.addForm.serviceType = this.data.title;
      this.addForm.GSTAmount = this.addForm.price * 5/100;

      this.addForm.netPrice = parseInt(this.addForm.price) + this.addForm.GSTAmount;
      

      this.addForm.serviceId = '63fdd52d3266f9e5227d1eb9'
      console.log(this.addForm,'gst')
      this.CrudService.add(this.addForm, 'billing').then((res: any) => {
        this.closedialog()
        this.toast.presentToast("Add service SuccessFully");
      })



    }

  }
  searchCompletemedicine() {
    console.log(this.searchVariable, 'searchComplete')
    let filter = {
      $or: [{
        "productName": { "$regex": this.searchVariable, "$options": "i" }
      },
      { "barCode": { "$regex": this.searchVariable, "$options": "i" } },
      ],
      "clientId": this.userProfile?.clientId?._id,
      "branchId": this.CrudService?.branchId
    }
    this.CrudService.get('food/search/mfa', filter).then((res: any) => {
      this.medicine = []
      this.food = []
      this.accessories = []


      res?.map((item: any) => {
        console.log(item?.Inventorytype, 'item')
        if (item?.Inventorytype == 'Medicine') {
          this.medicine.push(item)
          console.log(this.medicine,'medicine')

        }
        if (item?.Inventorytype == 'Food') {
          this.food.push(item)
          console.log(this.food, 'datatat')


        }
        if (item?.Inventorytype == 'Accessories') {
          this.accessories.push(item)

        }
      })
      console.log(this.medicine, 'this.medicine')


    })

  }
  searchComplete() {
    console.log(this.searchVariable, 'searchComplete')
    let filter = {
      where:{

        "serviceName": { "$regex": this.searchVariable, "$options": "i" },
        "type": this.data.title,
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }

    }
    this.CrudService.get(environment.api.service, filter).then((res: any) => {
      this.dataSource.data = res;
    })

  }
  closedialog() {
    this.dialogRef.close();
  }
  getServices() {
    let filter = {
      where: {
        "type": this.data.title,
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }


    }
    this.CrudService.get(environment.api.service, filter).then((res: any) => {
      this.dataSource.data = res;
      console.log(this.dataSource.data, 'this.dataSource.data')

    })
  }
  getmedicine() {
    this.dataSource.data = []
    let filter = {
      where: {
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }
    }
    this.CrudService.get(environment.api.medicine, filter).then((res: any) => {
      this.medicine = res
      console.log(this.medicine,'medicine')

    })
    this.CrudService.get(environment.api.food, filter).then((res: any) => {
      this.food = res
      console.log(this.food, 'datatat')


    })
    this.CrudService.get(environment.api.accessories, filter).then((res: any) => {
      this.accessories = res

    })
  }
  save(id: any) {

    if (this.data?.appoinmentId) {
      if (this.data.title == 'Diagnostic') {
        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          console.log(res, 'res')

          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })
        })

      }

      if (this.data.title == 'Treatment') {
        console.log(this.data.title, 'title')

        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })

          console.log(res, 'res')
        })

      }

      if (this.data.title == 'Nursing') {
        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.originalPrice = (res?.price ? res?.price : 0)


          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })

          console.log(res, 'res')
        })

      }
      if (this.data.title == 'Support') {
        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.serviceType = res?.type
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })

        })

      }
      var servicedata = {
        "lastUsed": new Date(),
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
        "dogId": this.data?.dogId,
        "ownerId": this.data?.ownerId,
      }
      this.CrudService.edit(servicedata, id, environment.api.service).then((res) => {

      })
    } else {
      if (this.data.title == 'Diagnostic') {
        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          console.log(res, 'res')

          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })
        })

      }

      if (this.data.title == 'Treatment') {
        console.log(this.data.title, 'title')

        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })

          console.log(res, 'res')
        })

      }

      if (this.data.title == 'Nursing') {
        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })

          console.log(res, 'res')
        })

      }
      if (this.data.title == 'Support') {
        this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
          this.addForm.serviceId = res?._id
          this.addForm.serviceName = res?.serviceName
          this.addForm.price = (res?.price ? res?.price : 0)
          this.addForm.originalPrice = (res?.price ? res?.price : 0)

          this.addForm.serviceType = res?.type
          this.addForm.GST = res?.SGST + res?.CGST
          this.addForm.netPrice = parseInt(res?.price) + res?.price * this.addForm.GST / 100
          this.addForm.GSTAmount = (res?.price * this.addForm.GST / 100 ? res?.price * this.addForm.GST / 100 : 0)

          this.CrudService.add(this.addForm, 'billing').then((res: any) => {
            this.closedialog()

            this.toast.presentToast("Add service SuccessFully");

          })

        })

      }
      var servicedata = {
        "lastUsed": new Date(),
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
        "dogId": this.data?.dogId,
        "ownerId": this.data?.ownerId,
      }
      this.CrudService.edit(servicedata, id, environment.api.service).then((res) => {

      })
    }

    console.log(this.addForm, 'addform');

  }
  savemedicine(id: any, title: any) {

    if (title == 'medicine') {
      this.CrudService.getbyid(id, environment.api.medicine).then((res: any) => {
        this.addForm.serviceId = res?._id
        this.addForm.serviceName = res?.productName
        this.addForm.price = this.addForm0.quantity * res?.RetailPrice
        this.addForm.serviceType = 'Medicine'
        this.addForm.GST = res?.SGST + res?.CGST
        this.addForm.originalPrice = res?.RetailPrice

        this.addForm.netPrice = this.addForm.price + (this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100)
        // this.addForm.netPrice = (this.addForm.price + (this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100) ? this.addForm.price + (this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100) : 0)
        this.addForm.GSTAmount = (this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100 ? this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100 : 0)
        this.addForm.quantity = this.addForm0.quantity
        let totalQuantity = res?.currentQuantity
        this.CrudService.add(this.addForm, 'billing').then((res: any) => {
          this.closedialog()

          this.toast.presentToast("Add service SuccessFully");
          var servicedata = {
          "currentQuantity": totalQuantity - this.addForm.quantity
          }
          this.CrudService.edit(servicedata, this.addForm.serviceId, 'medicine').then((res) => {
    
          })

        })
        

      })

    }
    if (title == 'food') {
      this.CrudService.getbyid(id, environment.api.food).then((res: any) => {
        this.addForm.serviceId = res?._id
        this.addForm.serviceName = res?.productName
        this.addForm.price = this.addForm1.quantity * res?.retailPrice
        this.addForm.serviceType = 'Food'
        this.addForm.GST = res?.SGST + res?.CGST
        this.addForm.netPrice = this.addForm.price + (this.addForm.GST * this.addForm1.quantity * res?.retailPrice / 100)
        this.addForm.originalPrice = res?.RetailPrice

        this.addForm.GSTAmount = (this.addForm.GST * this.addForm1.quantity * res?.retailPrice / 100 ? this.addForm.GST * this.addForm1.quantity * res?.retailPrice / 100 : 0)

        this.addForm.quantity = this.addForm1.quantity
        let totalQuantity = res?.currentQuantity

        this.CrudService.add(this.addForm, 'billing').then((res: any) => {
          this.closedialog()

          this.toast.presentToast("Add service SuccessFully");
          var servicedata = {
            "currentQuantity": totalQuantity - this.addForm.quantity
            }
            this.CrudService.edit(servicedata, this.addForm.serviceId, 'food').then((res) => {
      
            })
        })

      })

    }
    if (title == 'accessories') {
      this.CrudService.getbyid(id, environment.api.accessories).then((res: any) => {
        this.addForm.serviceId = res?._id
        this.addForm.serviceName = res?.productName
        this.addForm.price = this.addForm.quantity * res?.retailPrice
        this.addForm.serviceType = 'Accessories'
        this.addForm.GST = res?.SGST + res?.CGST
        this.addForm.netPrice = this.addForm.price + (this.addForm.GST * this.addForm.quantity * res?.retailPrice / 100)

        // this.addForm.netPrice = this.addForm.price + (this.addForm.GST * this.addForm.quantity * res?.RetailPrice / 100) 
        this.addForm.GSTAmount = (this.addForm.GST * this.addForm.quantity * res?.retailPrice / 100 ? this.addForm.GST * this.addForm.quantity * res?.retailPrice / 100 : 0)
        this.addForm.originalPrice = res?.RetailPrice

        this.addForm.quantity = this.addForm.quantity
        let totalQuantity = res?.currentQuantity

        this.CrudService.add(this.addForm, 'billing').then((res: any) => {
          this.closedialog()

          this.toast.presentToast("Add service SuccessFully");
          var servicedata = {
            "currentQuantity": totalQuantity - this.addForm.quantity
            }
            this.CrudService.edit(servicedata, this.addForm.serviceId, 'accessories').then((res) => {
      
            })
        })

      })

    }
    


  }
  quantityCalmfa(event: any, id: any, mainId: any, type: any){
    console.log(event,id,mainId,type,'rohit')
    if (type == 'Medicine') {
      this.CrudService.getbyid(id, environment.api.medicine).then((res: any) => {
        this.addForm.serviceId = res?._id
        this.addForm.serviceName = res?.productName
        this.addForm.price = event * res?.RetailPrice
        this.addForm.serviceType = 'Medicine'
        this.addForm.GST = res?.SGST + res?.CGST
        this.addForm.originalPrice = res?.RetailPrice

        this.addForm.netPrice = this.addForm.price + (this.addForm.GST * event * res?.RetailPrice / 100)
        // this.addForm.netPrice = (this.addForm.price + (this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100) ? this.addForm.price + (this.addForm.GST * this.addForm0.quantity * res?.RetailPrice / 100) : 0)
        this.addForm.GSTAmount = (this.addForm.GST * event * res?.RetailPrice / 100 ? this.addForm.GST * event * res?.RetailPrice / 100 : 0)
        this.addForm.quantity = event
        this.CrudService.edit(this.addForm, mainId, 'billing').then((res: any) => {

          if (this.data?.editId && this.data?.sessionId) {
            this.getbillingSession()
            
          } else {
              this.getbilling();

            }

      })

      })

    }
    if (type == 'Food') {
      this.CrudService.getbyid(id, environment.api.food).then((res: any) => {
        this.addForm.serviceId = res?._id
        this.addForm.serviceName = res?.productName
        this.addForm.price = event * res?.retailPrice
        this.addForm.serviceType = 'Food'
        this.addForm.GST = res?.SGST + res?.CGST
        this.addForm.originalPrice = res?.retailPrice

        this.addForm.netPrice = this.addForm.price + (this.addForm.GST * event * res?.retailPrice / 100)

        this.addForm.GSTAmount = (this.addForm.GST * event * res?.retailPrice / 100 ? this.addForm.GST * event * res?.retailPrice / 100 : 0)

        this.addForm.quantity = event

        this.CrudService.edit(this.addForm, mainId, 'billing').then((res: any) => {

          if (this.data?.editId && this.data?.sessionId) {
            this.getbillingSession()
            
          } else {
              this.getbilling();

            }
        })

      })

    }
    if (type == 'Accessories') {
      this.CrudService.getbyid(id, environment.api.accessories).then((res: any) => {
        this.addForm.serviceId = res?._id
        this.addForm.serviceName = res?.productName
        this.addForm.price = event * res?.retailPrice
        this.addForm.serviceType = 'Accessories'
        this.addForm.GST = res?.SGST + res?.CGST
        this.addForm.originalPrice = res?.retailPrice

        this.addForm.netPrice = this.addForm.price + (this.addForm.GST * event * res?.retailPrice / 100)

        // this.addForm.netPrice = this.addForm.price + (this.addForm.GST * this.addForm.quantity * res?.RetailPrice / 100) 
        this.addForm.GSTAmount = (this.addForm.GST * event * res?.retailPrice / 100 ? this.addForm.GST * event * res?.retailPrice / 100 : 0)

        this.addForm.quantity = event
        this.CrudService.edit(this.addForm, mainId, 'billing').then((res: any) => {

          if (this.data?.editId && this.data?.sessionId) {
            this.getbillingSession()
            
          } else {
              this.getbilling();

            }

        })
        // this.CrudService.add(this.addForm, 'billing').then((res: any) => {
        //   this.closedialog()

        //   this.toast.presentToast("Add service SuccessFully");

        // })

      })

    }
    

  }
  discountCal(event: any, id: any, mainId: any) {
    
    console.log('rohit')
    this.CrudService.getbyid(id, environment.api.service).then((res: any) => {
      console.log(event, 'discount', res?.maxDiscount);
      // if (res?.maxDiscount >= event) {
      if (event >= res?.maxDiscount) {
        console.log(event, 'discountvalue', res?.maxDiscount)
        this.toast.presentToast('Please provide discount less than ' + res?.maxDiscount)

      } else {

        this.CrudService.getbyid(mainId, 'billing').then((res: any) => {

          var discount = res?.price - res?.price / 100 * event
          console.log(discount, 'discount')

          var data = {
            "netPrice": discount + discount / 100 * res?.GST,
            "discount": event,
            "discountAmount": res?.price / 100 * event,
            "GSTAmount": discount + discount / 100 * res?.GST - discount
          }

          this.CrudService.edit(data, mainId, 'billing').then((res: any) => {

            setTimeout(() => {
              this.getbilling();

            }, 1000);

          })
          console.log(this.discount, 'discount');

        })
      }
      // } else {
      //   this.toast.presentToast('Please provide discount less than ' + res?.maxDiscount)

      // }


    })

  }
  discountCalmfa(event: any, id: any, mainId: any, type: any) {
    console.log(type, 'Food')
    if (type == 'Food') {
      this.collectionname = 'food'

    }
    if (type == 'Medicine') {
      this.collectionname = 'medicine'

    }
    if (type == 'Accessories') {
      this.collectionname = 'accessories'

    }

    this.CrudService.getbyid(id, this.collectionname).then((res: any) => {
      console.log(event, 'discount', res?.maxRetailDiscount);
      // if (res?.maxRetailDiscount >= event) {
      if (event?.target?.value >= res?.maxRetailDiscount) {
        this.toast.presentToast('Please provide discount less than ' + res?.maxRetailDiscount)

      } else {

        this.CrudService.getbyid(mainId, 'billing').then((res: any) => {
          var discount = res?.price - res?.price / 100 * event
          console.log(discount, 'discount')

          var data = {
            "netPrice": discount + discount / 100 * res?.GST,
            "discount": event,
            "discountAmount": res?.price / 100 * event,
            "GSTAmount": discount + discount / 100 * res?.GST - discount

          }

          this.CrudService.edit(data, mainId, 'billing').then((res: any) => {

            setTimeout(() => {
              this.getbilling();

            }, 1000);

          })
          console.log(this.discount, 'discount');

        })
      }
      // } else {
      //   this.toast.presentToast('Please provide discount less than ' + res?.maxRetailDiscount)

      // }


    })

  }
  delete(id: any) {
    
    this.CrudService.deleteid(id, 'billing').then((res: any) => {
      this.getbilling()
    })
  }
}
