<div *ngIf="routePath == '/'+slug+'/main/admin-staff'" class="page-title">
  <h3>
    Staffs
  </h3>
</div>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/admin-table'">
  Inventory
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/dashboard'">
  Dashboard : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/supplier'">
  Suppliers
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/service'">
  Services
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/branches'">
  Branches
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/consultation'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Consultation
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/customer-lookup'">
  Customer Lookup
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/roles-users'">
  Role & Users
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/admin-sales-transactions'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Sales
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/admin-table'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Inventory
</p>
<p class="sticky-Body-Nav heading-text-value" *ngIf="routePath == '/'+slug+'/manager-main/manage-users'">
  <button style="background-color: transparent;" (click)="back('roles-users')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Manage Users
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/follow-up'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Follow Up
</p>
