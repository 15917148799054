<div *ngIf="data.pet == 'PetSchedule'">
  <div class="header">
    <p class="choose">Choose Pet</p>
    <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon>
  </div>

  <div class="line">
    <img src="../../../../assets/Line 69.svg">
  </div>

  <div class="middle">
    <p>Time to play pet detective!</p>
    <p>Choose a furry friend from the customer's pet list.</p>

    <div class="petList">
      <ion-chip *ngFor="let data of petdata;let i =index" (click)="selectpet(data._id,data.name,i)"
        [ngClass]="selectpetval===data._id ? 'active' : ''" >
        <ion-icon name="checkmark" ></ion-icon>{{data.name}}
         <mat-form-field  class="datepicker">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="data.appoinmentTime" name="data.appoinmentTime"  [min]="todaydate" (dateInput)="show(data.appoinmentTime)" > 
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <ion-icon *ngIf="data.appoinmentTime" (click)="deleteappoint(deleteid)" class="icon1" name="close-outline"></ion-icon>
      </ion-chip>
    </div>
  </div>


  <div class="modalFooter">

    <p>Create a <button style="background-color: transparent;"><a (click)="openpet(data.id,data.name);"
          class="newPetTextBt">New Pet</a> </button> instead</p>
    <div>
      <button class="modalSecondaryBt" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" (click)="continuepage(petid,name)">Continue</button>
    </div>

  </div>
</div>


<div *ngIf="data.pet == 'PetCheck'">
  <div class="header">
    <p class="choose">Choose Check In Pet</p>
    <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon>
  </div>

  <div class="line">
    <img src="../../../../assets/Line 69.svg">
  </div>

  <div class="middle">
    <p>Time to play pet detective!</p>
    <p>Choose a furry friend from the customer's pet list.</p>
    <div class="petList">
      <ion-chip *ngFor="let data of petdata;let i =index" (click)="selectpet1(data._id,data.name,i)"
        [ngClass]="selectpetval===data._id ? 'active' : ''">
        <ion-icon name="checkmark" ></ion-icon>{{data.name}}
    
       
        </ion-chip>
    </div>
  </div>


  <div class="modalFooter">

    <p>Create a <button style="background-color: transparent;"><a class="newPetTextBt"
          (click)="openpet(data.id,data.name);">New Pet</a>
      </button> instead</p>
    <div>
      <button class="modalSecondaryBt" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" (click)="continuecheck(petid,name)">Continue</button>
    </div>

  </div>
</div>

<div *ngIf="data.pet == 'editpet'">
  <div class="header">
    <p class="choose">Choose Edit Pet</p>
    <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon>
  </div>

  <div class="line">
    <img src="../../../../assets/Line 69.svg">
  </div>

  <div class="middle">
    <p>Time to play pet detective!</p>
    <p>Choose a furry friend from the customer's pet list.</p>
    <div class="petList">
      <ion-chip *ngFor="let datas of petdata;let i =index" (click)="selectpet(datas._id,datas.name,i)"
        [ngClass]="selectpetval===datas._id ? 'active' : ''">
        <ion-icon name="checkmark"></ion-icon>{{datas.name}}
      </ion-chip>
    </div>
  </div>


  <div class="modalFooter">
    <p>Somthing Nice</p>
    <div>
      <button class="modalSecondaryBt" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" (click)="continueedit(petid)">Continue</button>
    </div>


  </div>
</div>