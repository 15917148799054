import { Component } from '@angular/core';
import { CrudService } from './services/crud/crud.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private crudService : CrudService) {
   if(localStorage.getItem("branchId")){
    this.crudService.branchId=localStorage.getItem("branchId");
    console.log(this.crudService.branchId,'branchid')

   }
  }
}
