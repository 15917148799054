// import { Component, OnInit } from '@angular/core';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { AccessoriesEditComponent } from 'src/app/components/accessories-edit/accessories-edit.component';
import { animate, state, style, transition, trigger } from '@angular/animations';


/** Constants used to fill up our data base. */
export interface PeriodicElement {
  Name: string;
  TAC: number;
  TDC: string;
  DND: number;
  AHT: number;
  Renewal: number;
}
@Component({
  selector: 'app-admin-accesories',
  templateUrl: './admin-accesories.component.html',
  styleUrls: ['./admin-accesories.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminAccesoriesComponent implements AfterViewInit {
  ngOnInit() {
  }
  displayedColumns: string[] = ['productName', 'type', 'brand', 'uses', 'material', 'color', 'currentQuantity', 'reorderLevel', 'reQuantity', 'purchasePrice', 'retailprice', 'maxRetailDiscount', 'barCode', 'Location', 'action'];
  dataSource: any = new MatTableDataSource([]);
  showChilds:any

  dataSourceWithPageSize: any = new MatTableDataSource([]);
  pageSize = 10;//
  skip = 0;
 
  limit: any = 100;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];//
  userRole:any
  dataAccessories: any=[];
  searchVariable: any;
  userProfile: any = localStorage.getItem('userProfile');
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: PeriodicElement | null | any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
   
  }


  constructor(
    public dialog: MatDialog,
    private CrudService: CrudService,
    private toast: ToastComponent


  ) {
    this.userProfile = JSON.parse(this.userProfile);
    this.userRole = this.userProfile?.role?.name;

    // Create 100 users

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
    
    this.getall();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, id: any): void {
    const dialogref = this.dialog.open(AccessoriesEditComponent, {
      height: '500px',
      width: '800px',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        title: 'accessories',
        id: id
      }

    });
    dialogref.afterClosed().subscribe((res: any) => {
      this.getall()


    })



  }
  searchComplete() {
    console.log(this.searchVariable)
    let filter = {

      "where": {
        $or: [{
          "productName": { "$regex": this.searchVariable, "$options": "i" }
        },
        { "barCode": { "$regex": this.searchVariable, "$options": "i" } },

        ],
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }
    }
    this.dataAccessories = []
    this.CrudService.get(environment.api.accessories, filter).then((res: any) => {
      this.dataSource = res;
      console.log(this.dataSource, 'this.dataFood')
      setTimeout(() => {
        if (!res.length) {
          this.toast.presentToastError("Data is not found related to search : " + this.searchVariable)
        }
      }, 100);
    })

  }
  getall() {
    let filter = {
      where: {
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }
    }

    this.CrudService.get(environment.api.accessories, filter).then((res : any) => {
      this.dataSource.data = []
      // this.dataSource = new MatTableDataSource(res);
      res?.map((item1:any)=>{
        if(item1?.accessoriesId == null || item1?.accessoriesId == ''){
          console.log(item1?.accessoriesId,'foodid')
          this.dataSource.data.push(item1) ;

        }
      })
      this.dataSource.paginator = this.paginator;
      this.dataSourceWithPageSize.paginator = this.paginatorPageSize;
      this.dataSource.sort = this.sort;
      console.log(this.dataSource, 'this.dataSource');
   
    })
  }
  deletebyid(id: any) {
    this.CrudService.deleteid(id, environment.api.accessories).then((res: any) => {
      this.toast.presentToast("Deletion Complete")
      this.getall();

    })

  }

  openDialogadd(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogref = this.dialog.open(DialogboxComponent, {
      height: '500px',
      width: '800px',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        title: 'AddAccessories'
      }

    });
    dialogref.afterClosed().subscribe((res: any) => {
      this.getall()


    })

  }
  AddNew(id:any){
    console.log(id,'id')
    
    const dialogref = this.dialog.open(DialogboxComponent, {
      height: '600px',
      width: '800px',
      data: {
        id:id,
        title: 'AddNewAccessories'
      }

    });
    dialogref.afterClosed().subscribe((res: any) => {
      this.getall()

    })

  }
  showChild(ids:any){
    this.showChilds=ids

  }

}
